body {
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  box-sizing: border-box;
}

#root {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.full-spin {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.full-spin .anticon {
  color: @primary-color;
}

.nav ~ .nav {
  font-size: 0.9em;
}

.breadcrumb-item.active a {
  font-weight: bold;
}

button {
  cursor: pointer;
}

button:hover i.fa + span {
  text-decoration: underline;
}

.ag-root i.fa {
  vertical-align: middle;
}

.clickable-table tr {
  cursor: pointer;
}

.ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: right;
}

aside .ant-btn > .anticon,
aside .ant-btn > span {
  white-space: normal;
  vertical-align: middle;
}

aside .ant-btn {
  text-align: left;
  height: auto;
  margin: 0.25em 0;
  padding: 0.5em 1.5em 0.5em 0.75em;
}

.ant-form-item {
  margin-bottom: 9px;
}

body > .root {
  display: flex;
}

header .top {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 10px 20px;
  background: @primary-color;
  background: radial-gradient(ellipse at top left, @theme-color 0, darken(@theme-color, 6%) 100%);
}

header .top img {
  width: 26px;
  vertical-align: middle;
  margin-right: 0.75em;
}

header .top button {
  background: none;
  border: none;
  font-weight: bold;
  color: white;
}

main {
  overflow-y: scroll;
}

div.loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1000;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input-icon {
  color: rgba(0,0,0,.25);
}

/* PublicPageLayout styles */
.public-page {
  height: 100vh;
  width: 100vh;
}

.public-page__splash-container {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #1890ff;
}

.public-page__splash-container img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  opacity: 0.7;
}

.public-page__icon {
  position: fixed;
  bottom: 20px;
  left: 20px;
  height: 120px;
  width: 120px;
}

.public-page__right-content {
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  width: 450px;
  max-width: 100%;
  height: 100%;
  background: white;
  padding: 40px;
}

.public-page__right-content form > span {
  margin-bottom: 1em;
}

h1.public-page__title {
  padding: 15px;
  font-weight: 500;
}

.public-page__disclaimer {
  padding: 20px;
  font-size: 13px;
  text-align: justify;
}

/* LoginForm styles */
.login-form {
  padding: 15px;
  z-index: 10;
  margin: auto;
  max-width: 350px;
}

.login-form a {
  font-size: 12px;
}

.login-form__submit {
  text-align: center;
}

.login-form__checkbox {
  font-size: 12px;
}

.login-form__button {
  width: 100%;
}

.ant-steps-item:not(.ant-steps-item-disabled) .ant-steps-item-title {
  text-decoration: underline;
  font-weight: bold;
}

.form-value .ant-btn {
  margin-left: 1em;
}

@media screen and (max-width: 45em) {
  .form-field .ant-btn {
    display: block;
    margin-bottom: 1em;
    margin-left: 0;
    width: 100%;
  }
}


.form-value .ant-btn:first-child {
  margin-left: 0;
}

form .form-field,
.form-field p {
  margin-bottom: 2em;
}

@media screen and (min-width: 45em) {
  .form-field {
    display: flex;
    align-items: top;
  }

  .form-label {
    width: 36vw;
    margin-right: 2em;
    text-align: right;
  }
}

.form-label label {
  font-weight: bold;
}

.form-label .form-help {
  font-size: 0.9em;
}

.form-label .form-required {
  color: red;
  font-size: 1.1em;
}

.form-error {
  color: red;
}

.form-value {
  width: 64vw;
}

.form-value .ant-radio-wrapper,
.form-value .ant-checkbox-wrapper {
  display: block;
  margin: 0.25em 0 0.75em;
}

.form-value textarea.ant-input {
  min-height: 6em;
}

.ant-page-header {
  padding: 8px 12px;
}

.ant-page-header + main {
  padding-top: 0;
}

.align-right {
  text-align: right;
}

.wizard {
  margin: 1em;
}

.site-clickable {
  cursor: pointer;
}

.pagination-in-footer {
  position: absolute;
  bottom: 0.25em;
  right: 0;
  display: inline-block;
  text-align: right;
}

.ant-tag.ant-dropdown-trigger, .site-tag-dashed {
  background: none;
  border-style: dashed;
  cursor: pointer;
}

.ant-page-header-heading-extra {
  white-space: normal;
  display: flex;
  align-items: center;
}

.ant-menu-horizontal > .ant-menu-submenu:not(.ant-menu-submenu-selected) > .ant-menu-submenu-title > a {
  color: rgba(0, 0, 0, 0.65);
}

.ant-page-header-compact .ant-page-header-heading {
  flex-wrap: nowrap;
}

@media screen and (max-width: 40em) {
  .ant-page-header-heading-extra button.ant-btn.ant-btn-icon-only {
    display: block;
    margin-left: 0;
    width: 100%;
    padding: 0 0.5em;
  }
}

.ant-modal-footer * + * {
  margin-left: 8px;
}

menu {
  padding: 0;
  margin: 0;
}

menu .ant-menu > .ant-menu-item > a {
  display: inline;
}
.ant-table-row {
  background: #fcfcfc;
}

.ant-table-row:nth-child(2n) {
  background: #f7f7f7;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 1;
}

.ruleEditorTree .ant-tree-treenode {
  align-items: center;
}

.ruleEditorTree .ant-tree-switcher {
  display: flex;
  align-items: center;
}

.ruleEditorBody {
  display: flex;
  flex-wrap: nowrap;
}

.ruleEditorMain {
  width: 100%;
  padding: 0.8em 1em 0.8em 0;
}

.ruleEditorBody h3 {
  margin-bottom: 1em;
}

.ruleEditorActions .ant-btn:not(:first-child) {
  margin-left: 0.5em;
}

.ruleEditorHistory {
  width: 20em;
  max-width: 30%;
  padding: 0.8em 0 0.8em 1em;
}

.ruleEditorHistory .ant-timeline {
  margin-top: 2em;
}

.br-command-buttons {
  text-align: right;
  margin-bottom: 0.5em;
}

.br-command-buttons .ant-btn {
  margin-left: 0.5em;
}

.br-command-buttons .ant-btn:first-child {
  margin-left: 0;
}

.br-command-buttons-vertical {
  text-align: center;
}

.br-command-buttons-vertical .ant-btn {
  margin: 0 0 0.5em;
}

.home {
  display: flex;
  width: 100%;
  height: 100vh;
  flex-wrap: wrap;
}

.home-commands {
  text-align: center;
  flex: 0 0 10em;
}

.home-content {
  flex: 1;
  margin-left: 2em;
}

.home-content .ant-tabs-top {
  margin-right: 1em;
}

.home-courses {
  flex: 1;
  height: 100vh;
  margin-left: 1em;
  margin-right: 2em;
}

.requirement-card-container {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.card-item {
  height: 8.5em;
  margin: .5em;
  min-width: 28em;
  flex-grow: 1;
}

.card-item .ant-card {
  height: 100%;
}

.card-item .ant-progress-show-info .ant-progress-outer {
  margin-right: calc(-2em - 10px);
}

/* Min height for Quill editors. */
.ql-container {
  min-height: 100px;
}

